<template>
    <div class="breadcrumbsPill noselect" v-if="routes && routes.current && routes.current.filter((r: any) => r.slug).length > 0">
        <div class="breadcrumbs">
            <div class="firstLinks" ref="breadcrumbs">
                <NuxtLinkLocale to="/">Home</NuxtLinkLocale>
                <NuxtLinkLocale v-for="route in routes.current.filter((r: any) => r.slug).slice(0, -1)" :to="route.slug">{{ route.label }}</NuxtLinkLocale>
            </div>
            <NuxtLinkLocale v-for="route in routes.current.filter((r: any) => r.slug).slice(-1)" :to="route.slug" ref="lastLink">{{ route.label }}</NuxtLinkLocale>
        </div>
    </div>
</template>

<script lang="ts" setup>
const props = defineProps<{ routes: any }>()

const breadcrumbs = ref<HTMLDivElement>()
const lastLink = ref<HTMLAnchorElement>()

const { width: breadcrumbsWidth } = useElementSize(breadcrumbs)
const { width: lastLinkWidth } = useElementSize(lastLink)

const breadcrumbsWidthPx = computed(() => {
    if (breadcrumbsWidth.value) return `${breadcrumbsWidth.value + lastLinkWidth.value}px`
    else return 'auto'
})
const lastLinkWidthPx = computed(() => {
    if (lastLinkWidth.value) return `${lastLinkWidth.value}px`
    else return 'auto'
})
</script>

<style lang="scss" scoped>
.breadcrumbsPill {
    display: inline-block;
    padding: 8px 12px;
    border-radius: 20px;
    background-color: var(--color-azure-dark);

    width: v-bind(lastLinkWidthPx);
    transition: width 200ms 0ms ease-in-out;
    overflow: hidden;

    .breadcrumbs {
        position: relative;
        font-size: var(--font-size-footer);
        line-height: var(--font-line-footer);

        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        align-items: baseline;
        flex-wrap: nowrap;

        .firstLinks {
            position: absolute;

            display: flex;
            align-items: baseline;
            flex-wrap: nowrap;

            right: v-bind(lastLinkWidthPx);

            a {
                opacity: 0;
                transition: opacity 200ms 0ms ease-in-out;

                &:after {
                    content: "\203A";
                    font-weight: normal;
                    margin: 0 5px;
                    opacity: 0.5;
                }
            }
        }

        a {
            display: inline-block;
            color: white;
            font-weight: 600;
            text-decoration: none;
            white-space: nowrap;

            max-width: calc(100vw - var(--margin-page) * 2 - 24px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

@media screen and (min-width: 1280px) {
    .breadcrumbsPill {
        &:hover {
            width: v-bind(breadcrumbsWidthPx);

            .breadcrumbs {
                .firstLinks {
                    a {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
</style>